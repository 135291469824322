.div-banner-principal {
  height: 100vh;
  .banner-principal {
    width: 100%;
    height: 100vh;
  }

  .thumbs-wrapper {
    display: none;
  }

  .carousel-status {
    display: none;
  }
}

.div-grid-about-us {
  background-image: url('../../assets//fundo-de-madeira.jpg');
  background-repeat: no-repeat;

  .thumbs-wrapper {
    display: none;
  }

  .carousel-status {
    display: none;
  }
}

.content-grid-about-us {
  padding: 50px;
}
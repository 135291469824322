@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
	--mainColor: #24100e;
	--mainColorLight: #5767aa;
	--textColor: #eee;
}

header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	padding: 0 2rem;
	background-color: var(--mainColor);
	color: var(--textColor);

  position: fixed;
  width: 100%;
	z-index: 9;
}

.content-header {
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
	position: relative;
}

nav a {
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none;
	font-family: 'Poppins', sans-serif;
	transition: 0.4s ease;
}

.link-loja {
	background: #fafafa;
	height: 40px;
	padding: .5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #000;
	border-radius: 4px;
}

nav a:hover {
	background: #fafafa;
	height: 40px;
	padding: .5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #000;
	border-radius: 4px;
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
	display: flex;
	align-items: center;
	position: absolute;
	right: 0;
}

.content-logo {
	display: flex;
	align-items: center;
}

.header-logo {
	max-width: 200px;
	object-fit: contain;
}

@media only screen and (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	nav a:hover {
		background: transparent !important;
		height: auto;
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #FFF;
		border-radius: 4px;
		opacity: .7;
	}

	.header-logo {
		max-width: 120px;
		height: 30px;
	}

	header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: var(--mainColor);
		transition: 1s;
		z-index: 9;
	}

	header div, nav {
		position: relative;
	}

	header .responsive_nav {
		transform: translateY(100vh);
	}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	nav a {
		font-size: 1.5rem;
	}
}
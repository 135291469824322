
.banner-princiapal {
	position: relative;
	background-image: url('../../assets/sobre-banner.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;
	
	display: flex;
	align-items: center;

	.banner-text-content {
		max-width: 1440px;
		width: 100%;
		margin: 0 auto;
	}
}

.title-about {
	// position: absolute;
	// top: 40%;
	color: #FFF;
	padding: 0 9rem;
	font-size: 3rem;
	max-width: 1000px;
	font-weight: 600;
}

.banner-princiapal img {
	width: 100%;
	height: 100vh;
	object-fit: cover;
}

/* styles for reveal */
.reveal {
  visibility: hidden;
}
/* styles for reveal */


/* Outside Pics CSS */
.pics-outside {
	margin: 100px 0;
}

.outside-content {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	gap: 40px;

	max-width: 1440px;
	margin: 0 auto;
}

.outside-content:last-child {
	padding-top: 125px;
}

.outside-pic {
	max-width: 500px;
}

.outside-pic img 	{
	max-width: 300px;
	border-radius: 50px;
	height: 450px;
	object-fit: cover;
}

.outside-title {
	font-weight: bold;
	color: #653e22;
	padding: 0 0 10px;
	max-width: 800px;
}

.outside-text {
	color: #653e22;
	font-size: 16px;
	font-weight: 400;
	max-width: 800px;
}

/* Outside Pics CSS */

/* Values CSS */
.content-values {
	background: #5d3d25;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 5rem;
}

.card-mission {
	background: #512100;
	width: 350px;
	height: 250px;
}

.card-vision {
	background: #582d0f;
	width: 350px;
	height: 250px;
}

.card-values {
	background: #82634c;
	width: 350px;
	height: 250px;
}

.title-card {
	font-size: 2rem;
	font-weight: 500;
	color: #FFF;
	padding: 55px 0 0 30px;
}

.text-card {
	font-size: 1rem;
	font-weight: 400;
	color: #FFF;
	padding: 4px 32px 0 30px;
}
/* Values CSS */

/* Grid Images CSS */
.content-grid-emmpresa {
	background-image: url('../../assets/fundo-de-madeira.jpg');
	background-repeat: no-repeat;
	width: 100%;

	padding: 70px 0;

	display: flex;
	justify-content: center;
	align-items: center;

	gap: 10px;
}

.grid-empresa-1 {
	display: flex;
	flex-direction: column;
	gap: 10px;
	height: 500px;
}

.grid-empresa-1-img {
	width: 305px;
	height: 350px;
	object-fit: cover;
}

.grid-empresa-1-img-2 {
	width: 305px;
	height: 220px;
	object-fit: cover;
}

.grid-empresa-2 {
	display: flex;
	flex-direction: column;
	gap: 10px;
	height: 500px;
}

.grid-empresa-2-img {
	width: 240px;
	height: 220px;
}

.grid-empresa-2-img-2 {
	width: 240px;
	height: 200px;
	object-fit: cover;
}

.grid-empresa-2-img-3 {
	width: 240px;
	height: 105px;
	object-fit: cover;
}

.grid-empresa-3 {
	height: 500px;
}

.grid-empresa-3 .grid-empresa-3-img{
	width: 375px;
	height: 500px;
	object-fit: cover;
}


.container-grid-mobile {
	display: none;
}
/* Grid Images CSS */

@media screen and (min-width: 50px) and (max-width: 1024px) {
	.title-about {
		font-size: 2rem;
		// max-width: 300px;
		padding: 0 2rem;
	}
	.container-grid-mobile {
		display: flex !important;
	}

	.content-grid-emmpresa  {
		display: none;
	}

	.outside-content {
		flex-direction: column;
	}

	.content-values  {
		flex-wrap: wrap;
		padding: 1rem 2rem;
	}
	
	.pics-outside {
		padding: 0 20px;
		margin: 60px 0;

		.outside-pic {
			max-width: none;

			img {
				width: 100%;
				height: 100%;
				max-width: none;
				border-radius: 0;
			}
		}

		.outside-text {
			max-width: none;

			.outside-title {
				max-width: none;
			}
		}
	}

	.banner-princiapal img {
		height: 100%;
	}

	.card-mission {
		background: #512100;
		width: 100%;
		height: auto;

		.title-card {
			padding: 4px 32px 0 30px;
			font-size: 1.5rem;
		}

		.text-card {
			padding: 4px 32px 10px 30px;
		}
	}
	
	.card-vision {
		background: #582d0f;
		width: 100%;
		height: auto;

		.title-card {
			padding: 4px 32px 0 30px;
			font-size: 1.5rem;
		}

		.text-card {
			padding: 4px 32px 10px 30px;
		}
	}
	
	.card-values {
		background: #82634c;
		width: 100%;
		height: auto;

		.title-card {
			font-size: 1.5rem;
			padding: 4px 32px 0 30px;
		}

		.text-card {
			padding: 4px 32px 10px 30px;
		}
	}
}
/* Footer Styling */
footer {
  width: 100%;
  height: auto;
  background-color: #24100e;

  .container-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
  }
  
  .section-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 80px;
    flex-wrap: wrap;
    padding-top: 30px;
  }
  
  .section-titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
  }
  
  .logo-footer {
    width: 200px !important;
    height: 90px !important;
    object-fit: contain !important;
    margin-left: -20px;
  }
  
  .section-title-first {
    color: #fff;
    font-weight: bold;
    text-align: center;
    margin-top:.5rem;
  }
  
  .section-title-second {
    color: #fff;
    text-align: left;
    margin-top: 1rem;
  }
  
  .section-title-third {
    color: #fff;
    text-align: justify;
    margin-top: 1rem;
  }
  
  .text-contact {
    color: #fff;
    font-weight: bold;
  }
  
  .text-number-first {
    margin-top: 1rem;
    color: #fff;
  }
  
  .text-number-second {
    margin-top: 1rem;
    color: #fff;
  }
  
  .text-email {
    margin-top: 1rem;
    color: #fff;
  }
  
  .container-manager {
    border-top: .9px solid gray;
    margin-top: 2rem
  }
  
  .container-manager p {
    text-align: center;
    color: #fafafa;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .social-section {
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    top: -9px;

    .social-icons {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    .social-icons a {
      color: #FFF;
      font-size: 1.2rem;
      padding: 1rem 0;
      transition: 0.4s ease;

      &:hover {
        transform: scale(1.2);
      }
    }

  }
  
  .social-section p {
    color: #fff;
    font-weight: bold;
  }
}
/* Footer Styling */

@media screen and (min-width: 50px) and (max-width: 1024px) {
  footer {
    .section-title {
      padding: 30px 1rem 0;
    }

    .section-title-second {
      max-width: 400px;
    }

    .section-titles {
      width: 100%;
    }

    .section-contact {
      p {
        text-align: center;
      }
    }

    .social-section {
      p {
        text-align: center;
      }
    }

    .logo-footer {
      width: 130px !important;
      height: 90px !important;
      object-fit: contain !important;
      margin: 0;
    }
  }
}

.div-banner-principal-mobile {
  
  .banner-principal {
    width: 100%;
    height: 100vh;
  }

  .thumbs-wrapper {
    display: none;  
  }
  
  .carousel-status {
    display: none;
  } 
}

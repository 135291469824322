.container-about {
  padding: 8rem 0 2rem;

	.content-about {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;  

    img {
      padding: 0 0 20px;
			max-width: 300px;
    }
  
    .text-about {
      font-size: 1rem;
      line-height: 20px;
      color: #121212;
      padding: 0 0 30px;
      text-align: center;
			max-width: 1000px;
    }
  
    .container-links-about {
      display: flex;
      flex-direction: row;
      gap: 18px;
			flex-wrap: wrap;
			justify-content: center;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
				max-width: 220px;
				width: 150px;
				height: 50px;
        color: #121212;
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 20px;
        font-weight: 500;
        cursor: pointer;
        padding: 0 18px;
        text-decoration: none;
				transition: 0.4s ease;
      
				&:hover {
					background: #512100;
					border: 0;
					max-width: 220px;
					width: 150px;
					height: 50px;
					border-radius: 30px;
					color: #FFF;
				}
			}
    }
  }
}

/* Form CSS */
.container-form {
	background-image: url('../../assets/ofuscado-fundo.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	padding: 150px 0;
}

.content-form {
	display: flex;
	justify-content: center;
	flex-direction: row;
	gap: 150px;
	max-width: 1000px;
	margin: 0 auto;
}

.content-form form {
	display: flex;
	flex-direction: column;
	max-width: 450px;
	width: 100%;
	gap: 20px;
}

.content-form form input {
	height: 60px;
	border: 0;
	background: #603c20;
	padding: 20px 30px;
	border-radius: 10px;

	font-size: 1rem;
	color: #FFF;
	line-height: 20px;
}

.content-form form input::placeholder {
	color: #FFF;
	font-weight: 500;
}

.content-form form textarea::placeholder {
	color: #FFF;
	font-weight: 500;
}

.content-form form textarea {
	height: 200px;
	border: 0;
	background: #603c20;
	padding: 20px 30px;
	border-radius: 10px;

	font-size: 1rem;
	color: #FFF;
	line-height: 20px;

	resize: none
}

.btn-send-form {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 150px;
	height: 40px;
	border: 0;
	font-size: 16px;
	font-weight: 600;
	color: #FFF;
	cursor: pointer;
	background: transparent;
	transition: 0.4s ease;

	&:hover {
		max-width: 150px;
		height: 40px;
		border-radius: 30px;
		background: #FFF;
		color: #000;
	}
}

.title-contato {
	font-weight: bold;
	color: #FFF;
	font-size: 40px;
	padding: 0 0 40px 0;
}

.sub-text {
	color: #FFF;
	font-size: 16px;
	
}

.text-street {
	padding: 42px 0;
}

.text-street p {
	color: #FFF;
	font-weight: 400;
}

.text-acompanhe {
	font-size: 1rem;
	color: #FFF;
	line-height: 10px;
	font-weight: bold;
	padding: 10px 0;
}

.container-social-links {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.container-social-links a svg {
	color: #FFF;
	font-size: 1rem;
	transition: 0.4s ease;

	&:hover {
		transform: scale(1.2);
	}
}
/* Form CSS */

.container-catalogo {
	.content-catalogo {
		max-width: 1440px;
		margin: 0 auto;
	}
}

.slider-mobile {
	display: none;
}

.container-video {
	margin: 0 auto;
	background-image: url('../../assets/background-video.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	align-items: center;

	video {
    max-width: 500px;
    padding: 3rem 1rem;
    width: 100%;
	}
}

@media screen and (min-width: 50px) and (max-width: 1024px) {
	.slider-mobile {
		display: block !important;
	}

	.slider-desktop {
		display: none;
	}

	.container-about {
		padding: 2rem 20px;
		margin: 0;

		.content-about {

			img {
				width: 100%;
				max-width: 300px;
			}
		}
	}

	.container-form {
		padding: 0;

		.content-form {
			flex-direction: column;
			padding: 0 20px;
			gap: 0;

			.title-contato  {
				padding: 14px 0 36px 0;
				font-size: 24px;
			}
	
			form {
				max-width: none;
				padding: 30px 0 10px 0;
			}
		}
	}

	.container-links-about {

		a {
			max-width: none !important;
    	width: 100% !important;
		}
	}
}



